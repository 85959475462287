import React from 'react';
import { CustomLinkProps } from 'types';

/**
 * A link that opens in a new tab.
 *
 * This includes the `noopener` attribute, but it intentionally does not include
 * the `noreferrer` attribute: we want to pass ourselves as the referrer. The
 * `noopener` by itself is enough to ensurethat the window is reset, and that
 * there are no security issues.
 */
const ExternalLink: React.FC<CustomLinkProps> = ({ children, ...rest }) => (
    <a target="_blank" rel="noopener" {...rest}>
        {children}
    </a>
);

export default ExternalLink;

/**
 * A link that opens in a new tab.
 *
 * This variant of ExternalLink includes the `noreferrer` attribute (i.e. it
 * also does not pass the referrer to the new tab).
 */
export const ExternalLinkWithoutReferrer: React.FC<CustomLinkProps> = ({
    children,
    ...rest
}) => (
    <a target="_blank" rel="noopener noreferrer" {...rest}>
        {children}
    </a>
);
