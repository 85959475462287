/**
 * The formats supported by the "/download" page.
 * e.g. "/download/apk"
 */
export enum DownloadFormat {
    APK = 'apk',
    AppImage = 'AppImage',
    DEB = 'deb',
    DMG = 'dmg',
    EXE = 'exe',
    RPM = 'rpm',
    Desktop = 'desktop',
}

/** URLs of various places the Ente app can be downloaded/used from. */
export class DownloadURL {
    static readonly appStore =
        'https://apps.apple.com/app/id1542026904';
    static readonly googlePlay =
        'https://play.google.com/store/apps/details?id=io.ente.photos';
    static readonly fDroid =
        'https://f-droid.org/packages/io.ente.photos.fdroid/';
    static readonly web = 'https://web.ente.io';
}
