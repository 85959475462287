import { DownloadURL } from 'constants/download';
import { StaticImage } from 'gatsby-plugin-image';
import AppStoreBadgeImage from 'images/download/app-store-badge.svg';
import WebBadgeImage from 'images/download/web-badge.svg';
import React, { PropsWithChildren } from 'react';
import ExternalLink from 'components/links/ExternalLink';
import { Link } from 'gatsby';

// The badge images are of roughly equal but slightly different aspect ratios
// and sizes. To make them look consistent, they are all set to the same width,
// and placed centered in the same sized container.
const badgeWidth = 197;
const containerStyle = { width: '210px', height: '70px' };

const BadgeContainer: React.FC<PropsWithChildren> = ({ children }) => (
    <div
        className="d-flex justify-content-center align-items-center"
        style={containerStyle}>
        {children}
    </div>
);

export const AppStoreButton: React.FC = () => (
    <BadgeContainer>
        <ExternalLink href={DownloadURL.appStore}>
            {/* StaticImage doesn't support SVGs, so we need to use img */}
            <img
                src={AppStoreBadgeImage}
                width={badgeWidth}
                height={66}
                alt="Download Ente on the iOS App Store"
                loading='lazy'
            />
        </ExternalLink>
    </BadgeContainer>
);

export const PlayStoreButton: React.FC = () => (
    <BadgeContainer>
        <ExternalLink href={DownloadURL.googlePlay}>
            <StaticImage
                src="../images/download/play-store-badge.png"
                width={badgeWidth}
                height={59}
                alt="Download Ente on the Google Play Store"
                objectFit="contain"
                placeholder="none"
                loading='lazy'
            />
        </ExternalLink>
    </BadgeContainer>
);

export const FDroidButton: React.FC = () => (
    <BadgeContainer>
        <ExternalLink href={DownloadURL.fDroid}>
            <StaticImage
                src="../images/download/f-droid-badge.png"
                width={badgeWidth}
                height={59}
                alt="Download Ente on F-Droid"
                objectFit="contain"
                placeholder="none"
                loading='lazy'
            />
        </ExternalLink>
    </BadgeContainer>
);

export const WebButton: React.FC = () => (
    <BadgeContainer>
        <ExternalLink href={DownloadURL.web}>
            {/* StaticImage doesn't support SVGs, so we need to use img */}
            <img
                src={WebBadgeImage}
                width={badgeWidth}
                height={59}
                alt="Sign into the Ente web app"
                loading='lazy'
            />
        </ExternalLink>
    </BadgeContainer>
);

export const DesktopButton: React.FC = () => (
    <BadgeContainer>
        <Link to="/download/desktop">
            <StaticImage
                src="../images/download/desktop-badge.png"
                width={badgeWidth}
                height={59}
                alt="Download ente's desktop app"
                objectFit="contain"
                placeholder="none"
                loading='lazy'
            />
        </Link>
    </BadgeContainer>
);
